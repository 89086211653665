<template>
  <ess-page-content title="Messages">
    <v-row>
      <v-col cols="12"
             sm="6"
             offset-sm="6">
        <ess-text-field v-model="search"
                        placeholder="Search"
                        solo
                        hide-details
                        append-icon="mdi-magnify"
                        clearable />
      </v-col>
      <v-col cols="12">
        <ess-card>
          <ess-empty-message icon="mdi-forum-outline"
                             icon-color="light-green lighten-1"
                             message="No Messages"
                             sub-message="You have no Chat Messages currently" />
        </ess-card>
      </v-col>
    </v-row>
  </ess-page-content>
</template>

<script>
export default {
  components: {},

  props: {},

  data() {
    return {
      search: '',
    };
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeDestroy() {},

  destroy() {},

  methods: {},
};
</script>
